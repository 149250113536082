import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { getConfig } from "../config";
import { useAuth0 } from "@auth0/auth0-react";

const SwaggerUIComponent = () => {
    const { apiOrigin } = getConfig();
    const { getAccessTokenSilently } = useAuth0();

    // Configure Swagger UI with the current user's access token
    const requestInterceptor = async (req) => {
        const token = await getAccessTokenSilently();
        req.headers.Authorization = `Bearer ${token}`;
        return req;
    };

    return (
        <>
            <style>
                {`.scheme-container { display: none !important; }`}
            </style>
            <SwaggerUI
                url={`${apiOrigin}/openapi.json`}
                requestInterceptor={requestInterceptor}
                plugins={[
                    () => ({
                        wrapComponents: {
                            schemesContainer: () => () => null
                        }
                    })
                ]}
            />
        </>
    );
};

export default SwaggerUIComponent;